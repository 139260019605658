import { useEffect } from "react";
import { useAppStore } from "../store";
import { useAuth } from "./auth";

export const CloudPermissions = {
  FULL_WRITE: "full_write",
  READ_ONLY: "read_only",
};

export const useInitializeUserPermissions = (organizationId?: string) => {
  const fetchUserPermissions = useAppStore(
    (state) => organizationId && state.fetchUserPermissions,
  );

  const { user } = useAuth();

  useEffect(() => {
    if (user && fetchUserPermissions && organizationId) {
      fetchUserPermissions(organizationId);
    }
  }, [user, organizationId, fetchUserPermissions]);
};

export const useUserPermissions = () => {
  return useAppStore((state) => ({
    role: state.role,
    permissions: state.permissions,
    loading: state.loading,
    error: state.error,
  }));
};

export const useHasPermission = (permissionName: string) => {
  return useAppStore((state) => state.hasPermission(permissionName));
};
